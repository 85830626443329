import React, {useCallback, useEffect, useState} from 'reactn';
import HomeView from './Home-view';

const Home = props => {

	// component state
	const [bioData, setBioData] = useState();

	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {
			closeBio();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {
		  document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);

	function closeBio() {
		setBioData();
	}

	function openBio(name) {
		switch(name) {
			case 'davidKirven':
				setBioData({
					name: "David Kirven",
					title: "Music Director",
					text: (
						<>
							<p>Originally from Paris, Texas, David holds a Bachelor of Music degree in Tuba Performance from Baylor University, and Master of Music Degrees in Instrumental Conducting and Tuba Performance from The University of Kentucky.</p>
							<p>David is a tuba performer with The U.S. Army Concert Band “Pershing&rsquo;s Own”. He also serves at the Artistic Director of the Old Bridge Chamber Orchestra and is the Director of Music at Fredericksburg United Methodist Church.</p>
						</>
					)
				});
				break;
			case 'lanceVining':
				setBioData({
					name: "L. Thomas Vining",
					title: "Conductor Emeritus",
					text: (
						<>
							<p>Lance Vining attended Westminster Choir College, Princeton, NJ, and Peabody Conservatory, Baltimore, MD. Currently, he is the Director of Worship and Music at Vienna Presbyterian Church, Vienna, VA.</p>
							<p>Before becoming Director of Worship, Lance served Vienna Presbyterian as Director of Music for sixteen years. He has performed as a tenor soloist with many orchestras, including the New York Philharmonic and the National Symphony. He is the former conductor of The Oratorio Society of Virginia and has guest conducted numerous orchestras nationwide.</p>
						</>
					)
				});
				break;
			case 'johnCradler':
				setBioData({
					name: "John Cradler",
					title: "Director, Youth Ensemble",
					text: (
						<>
							<p>John Cradler is the former Principal Tuba of the U.S. Marine Band, &ldquo;The President&rsquo;s Own&rdquo; in Washington, D.C. During his career with &ldquo;The President&rsquo;s Own&rdquo;, he performed for five U.S. Presidents, toured 48 of the United States, and performed in Japan, Singapore, and Switzerland. He has performed under the batons of John Williams, Osmo Vanska, Giancarlo Guerrero, Gerard Schwarz, Timothy Reynish, and many other world-renowned conductors.</p>
							<p>In addition to his duties with the Marine Band, Cradler has performed with The Washington Ballet, The Chesapeake Orchestra, The National Philharmonic Orchestra, The National Gallery Orchestra, The Brass Band of Battle Creek, and The Cleveland Opera. He is a founding member of the Tuba-Euphonium Quartet: <em>Off Bass Brass</em>, which has produced two award winning albums.</p>
							<p>Over the course of his career, Cradler has taught students ranging in age from 10 to 77 years old. His performing and teaching skills have earned him several invitations to International Tuba and Euphonium Conferences.</p>
							<p>Cradler earned his Bachelor of Music degree from The University of Wisconsin-Madison and his Master of Music Degree from The University of Akron.</p>
						</>
					)
				});
				break;
			case 'markHerzing':
				setBioData({
					name: "Mark Herzing",
					title: "Manager",
					text: (
						<>
							<p>Mark Herzing has been a member of the Cathedral Brass since 1989, and assumed the role of Manager in 2004. A graduate of Lawrence University and the John F. Kennedy School of Government, Herzing is an experienced tuba player who has performed in a wide variety of ensembles in the DC metro area, including the City of Fairfax Band, the Potomac Brass Quintet, and the Kaleidoscope Jazz Orchestra.</p>
						</>
					)
				});
				break;

			default:
				setBioData();
		}
	}

	return (
		<HomeView
			bioData = {bioData}
			closeBio = {closeBio}
			openBio = {openBio}
		/>
	)
}

export default Home;
